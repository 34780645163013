import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnOfficialBnr from '../atoms/columnOfficialBnr'
import ColumnSideNewList from '../molecules/columnSideNewList'
import AdsenseSide300x250 from '../atoms/adsenseSide300x250'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.div`
  background: #ffffff;
  margin: 0 auto;
  padding: 60px 0;
  width: 1080px;
  display: flex;
  justify-content: space-between;

  main {
    width: 100%;
    max-width: 700px;
  }

  aside {
    width: 300px;

    .ad-wrap {
      width: 300px;
      height: 250px;
      background: #cccccc;
      margin-bottom: 30px;
    }

    .fixed-wrap {
      position: sticky;
      top: 130px;
    }
  }

  .addStyle {
    display: inline-block;
    width: 200px;
    height: 360px;
  }

  ${BreakPoints.large} {
    display: block;
    width: calc(100vw - 20px);

    main {
      padding: 0 10px;
    }

    aside {
      padding: 0 10px;
      width: calc(100% - 20px);

      .ad-wrap {
        margin: 0 auto 30px;
      }
    }
  }
`

type Props = {
  /** 子要素 */
  children: ReactNode
}

/**
 * コラム用メイン部分レイアウト
 * @constructor
 */
const ColumnBody = ({ children }: Props) => {
  const routeParam1 = useRouteParam('https://www.kintaicloud.jp/benefit')
  const routeParam2 = useRouteParam(
    'https://www.kintaicloud.jp/download/?utm_source=column&utm_medium=side_banner&utm_campaign=document_download&utm_id=banner'
  )

  return (
    <Wrapper>
      <main>{children}</main>
      <aside>
        {/* <div className="ad-wrap">広告バナー予定</div>
      <ColumnSideRelationList /> */}
        <div className="fixed-wrap">
          <ColumnSideNewList />
          <ColumnOfficialBnr
            href={routeParam1}
            imgId="sideBanner002.png"
            target="_blank"
            label="導入効果"
          />
          <ColumnOfficialBnr
            href={routeParam2}
            target="_blank"
            imgId="sideBanner001.png"
            label="資料ダウンロード"
          />
          {/** 広告挿入テスト：キンクラ_ディスプレイ_固定_サイドメニュー用_300x250 */}
          <AdsenseSide300x250 />
        </div>
      </aside>
    </Wrapper>
  )
}

export default ColumnBody
