import styled from '@emotion/styled'
import React from 'react'
import ColumnBtnCard from '../atoms/columnBtnCard'
import ColumnH1 from '../atoms/columnH1'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-list-link {
    position: absolute;
    top: 9px;
    right: 2px;
    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 7px;
      left: -14px;
      border-left: 8px solid #3ec7b3;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

/**
 * 記事検索結果カードコンポーネント
 * @constructor
 */
const ColumnSearchCards = () => {
  const routePram = useRouteParam('/column/detail')
  return (
    <Wrapper>
      <ColumnH1 label="検索結果：検索キーワード（XXXX件）" />
      <section>
        <ColumnBtnCard
          href={routePram}
          imgId="column0001.jpg"
          label="記事タイトル記事タイトル記事タイトル記事タイトル"
          date="2022.01.01"
        />
        <ColumnBtnCard
          href={routePram}
          imgId="column0001.jpg"
          label="記事タイトル記事タイトル記事タイトル記事タイトル"
          date="2022.01.01"
        />
        <ColumnBtnCard
          href={routePram}
          imgId="column0001.jpg"
          label="記事タイトル記事タイトル記事タイトル記事タイトル"
          date="2022.01.01"
        />
        <ColumnBtnCard
          href={routePram}
          imgId="column0001.jpg"
          label="記事タイトル記事タイトル記事タイトル記事タイトル"
          date="2022.01.01"
        />
        <ColumnBtnCard
          href={routePram}
          imgId="column0001.jpg"
          label="記事タイトル記事タイトル記事タイトル記事タイトル"
          date="2022.01.01"
        />
        <ColumnBtnCard
          href={routePram}
          imgId="column0001.jpg"
          label="記事タイトル記事タイトル記事タイトル記事タイトル"
          date="2022.01.01"
        />
      </section>
    </Wrapper>
  )
}

export default ColumnSearchCards
