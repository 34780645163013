import { graphql } from 'gatsby'
import React from 'react'
import SEO from '../../components/atoms/seo'
import { ITopicPath } from '../../components/atoms/topicPath'
import ColumnSearchCards from '../../components/molecules/columnSearchCards'
import ColumnBody from '../../components/organisms/columnBody'
import ColumnLayout from '../../components/organisms/columnLayout'

/**
 * コラム記事検索画面
 * @constructor
 */
const ColumnSearchPage = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: '/column' },
    { title: 'コラム記事検索' },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO title="キンクラコラム記事一覧" />
      <ColumnBody>
        {/** 検索キーワードにヒットした記事を表示 */}
        <ColumnSearchCards />
      </ColumnBody>
    </ColumnLayout>
  )
}

export default ColumnSearchPage

export const pageQuery = graphql`
  query ColumnSearchPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
